// i18n
import 'src/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { SWRConfig } from 'swr';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
import { SocketProvider } from './utils/socket';
import { UserSettingsProvider } from './user-settings';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <SocketProvider>
      <AuthProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: process.env.REACT_APP_THEME_MODE, // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: process.env.REACT_APP_THEME_COLOR_PRESET, // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <AuthConsumer>
                  <UserSettingsProvider>
                    <SWRConfig
                      value={{
                        revalidateOnFocus: false,
                      }}
                    >
                      <Router />
                    </SWRConfig>
                  </UserSettingsProvider>
                </AuthConsumer>
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </AuthProvider>
    </SocketProvider>
  );
}
