import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// LESSON
const FriendsListPage = lazy(() => import('src/pages/friends/list'));
const FriendFinderPage = lazy(() => import('src/pages/friends/finder'));
const FriendInvitePage = lazy(() => import('src/pages/friends/invite'));

// ----------------------------------------------------------------------

export const friendsRoutes = [
  {
    path: 'friends',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <FriendsListPage />, index: true },
      { path: 'find', element: <FriendFinderPage /> },
      { path: 'invite', element: <FriendInvitePage /> },
    ],
  },
];
