import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
// api
import { endpoints } from 'src/utils/axios';
import { markNotificationAsRead, markNotificationsAsReadOptions } from 'src/api/notifications';
// locales
import { useLocales } from 'src/locales';
// utils
import { fToNow } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, mutate }) {
  const { t } = useLocales();

  const handleClickNotification = async () => {
    try {
      if (notification.is_unread) {
        const newNotificationData = await markNotificationAsRead({
          url: endpoints.notifications.markAsRead,
          notificationId: notification.id,
          status: 'clicked',
        });

        // The server won't return the related data (sender_user, content, type) during updates.
        // So we need to delete them from the newNotificationData object, before we mutate the cache.
        delete newNotificationData.sender_user;
        delete newNotificationData.content;
        delete newNotificationData.type;

        await mutate(newNotificationData, markNotificationsAsReadOptions(notification));
      }

      // drawer.setOff();
    } catch (error) {
      console.log(error);
    }
  };

  const renderAvatar = (
    <ListItemAvatar>
      {notification.sender_user?.avatar_media?.url ? (
        <Avatar
          src={notification.sender_user?.avatar_media?.url}
          sx={{ bgcolor: 'background.neutral' }}
        />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            bgcolor: 'background.neutral',
          }}
        >
          <Box
            component="img"
            src={`/assets/icons/notification/${
              (notification.type?.name === 'new_login' && 'ic_grand_master_key') ||
              (notification.type === 'chat' && 'ic_chat') ||
              (notification.type === 'mail' && 'ic_mail') ||
              (notification.type === 'delivery' && 'ic_delivery')
            }.svg`}
            sx={{ width: 32, height: 32 }}
          />
        </Stack>
      )}
    </ListItemAvatar>
  );

  const notificationCode = `notifications.types.${notification.type?.name}`;
  let title = t(notificationCode);

  if (title === notificationCode) {
    title = notification.type?.description;
  }

  if (notification.content) {
    title = notification.content;
  }

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(title)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{ width: 2, height: 2, bgcolor: 'currentColor', mx: 0.5, borderRadius: '50%' }}
            />
          }
        >
          {fToNow(notification.created_at)}
          {t(`notifications.categories.${notification.type?.category}`)}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = notification.is_unread && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  return (
    <ListItemButton
      disableRipple
      onClick={handleClickNotification}
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>{renderText}</Stack>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  mutate: PropTypes.func,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0 },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
