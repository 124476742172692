import { useEffect } from 'react';
import { m } from 'framer-motion';
import useSWR from 'swr';
// @mui
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useAuthContext } from 'src/auth/hooks';
// api
import socket, { useSocketContext } from 'src/utils/socket';
// locales
import { useLocales } from 'src/locales';
// api
import { endpoints, no_reload_config } from 'src/utils/axios';
import { addNewNotificationOptions, getNotifications, markAllAsRead } from 'src/api/notifications';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import NotificationItem from './notification-item';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { user } = useAuthContext();
  const userPrivateId = user?.private_id;
  const { t } = useLocales();
  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');

  // getNotifications
  const {
    data: notifications,
    mutate,
    isLoading,
  } = useSWR({ url: endpoints.notifications.get }, getNotifications, no_reload_config);

  const totalUnRead = notifications?.filter((item) => item.is_unread === true).length || 0;

  const { enterRoom, leaveRoom } = useSocketContext();

  useEffect(() => {
    // We can not join room if the notifications is not available yet.
    if (isLoading) {
      return () => {};
    }

    const roomId = `/users/${userPrivateId}/notifications`;
    enterRoom(roomId);

    socket.on('new_notification', async (data) => {
      try {
        await mutate(data, addNewNotificationOptions(data));
      } catch (error) {
        console.log(error);
      }
    });

    return () => {
      leaveRoom(roomId);
      socket.off('new_notification');
    };
  }, [enterRoom, leaveRoom, isLoading, userPrivateId, mutate]);

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead({ url: endpoints.notifications.markAllAsRead });
      await mutate();
    } catch (error) {
      console.log(error);
    }
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('notifications.title')}
      </Typography>

      {!!totalUnRead && (
        <Tooltip title={t('notifications.mark_all_as_read')}>
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )}

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      <List disablePadding>
        {notifications?.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} mutate={mutate} />
        ))}
      </List>
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        {renderList}
      </Drawer>
    </>
  );
}
