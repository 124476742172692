// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  ACCOUNT: '/account',
  DASHBOARD: '/dashboard',
  STUDENTS: '/students',
  LESSONS: '/lessons',
  LEXIS: '/lexis',
  USERS: '/users',
  FRIENDS: '/friends',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
      register: `${ROOTS.AUTH}/register`,
    },
    confirm_email: `${ROOTS.AUTH}/confirm`,
    password_reset: `${ROOTS.AUTH}/password-reset`,
    new_password: `/new-passwrod`,
    delete_account: `/delete-account`,
  },

  account: {
    root: ROOTS.ACCOUNT,
    settings: `${ROOTS.ACCOUNT}/settings`,
  },

  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,

    class: {
      root: `${ROOTS.DASHBOARD}/class`,
      new: `${ROOTS.DASHBOARD}/class/new`,
      list: `${ROOTS.DASHBOARD}/class/list`,
    },
  },

  lessons: {
    root: `${ROOTS.LESSONS}`,
    new: `${ROOTS.LESSONS}/new`,
    list: `${ROOTS.LESSONS}/list`,
    view: (id) => `${ROOTS.LESSONS}/${id}`,
    edit: (id) => `${ROOTS.LESSONS}/${id}/edit`,
    text: (id, target_text_id, native_text_id) =>
      `${ROOTS.LESSONS}/${id}/texts/${target_text_id}/${native_text_id}`,
  },

  friends: {
    root: `${ROOTS.FRIENDS}`,
    list: `${ROOTS.FRIENDS}`,
    find: `${ROOTS.FRIENDS}/find`,
    invite: `${ROOTS.FRIENDS}/invite`,
  },

  users: {
    friends: {
      root: (id) => `${ROOTS.USERS}/${id}/friends`,
      list: (id) => `${ROOTS.USERS}/${id}/friends`,
    },
  },

  students: {
    root: `${ROOTS.STUDENTS}`,
    new: `${ROOTS.STUDENTS}/new`,
    list: `${ROOTS.STUDENTS}/list`,
    view: (id) => `${ROOTS.STUDENTS}/${id}`,
    edit: (id) => `${ROOTS.STUDENTS}/${id}/edit`,
  },

  lexis: {
    root: `${ROOTS.LEXIS}`,
    new: `${ROOTS.LEXIS}/new`,
    list: `${ROOTS.LEXIS}/list`,
    view: (id) => `${ROOTS.LEXIS}/${id}`,
    edit: (id) => `${ROOTS.LEXIS}/${id}/edit`,
  },
};
