import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
// locales
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  monster: icon('ic_monster_2'),
  group: icon('ic_users_group'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  return useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        // subheader: t('menu_teacher'),
        items: [{ title: t('dashboard.title'), path: paths.dashboard.root, icon: ICONS.dashboard }],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        // subheader: t('menu_management'),
        items: [
          // CLASSES
          {
            title: t('class.list.title'),
            path: paths.dashboard.class.list,
            icon: ICONS.folder,
            // disabled: true,
            // hidden: true,
          },

          // STUDENTS
          {
            title: t('student.list.title'),
            path: paths.students.root,
            icon: ICONS.user,
          },

          // FRIENDS
          {
            title: t('friend.list.title'),
            path: paths.friends.root,
            icon: ICONS.group,
          },

          // LESSONS
          {
            title: t('lesson.list.title'),
            path: paths.lessons.root,
            icon: ICONS.kanban,
          },

          // LEXIS
          {
            title: t('lexi.list.title'),
            path: paths.lexis.root,
            icon: ICONS.monster,
          },
        ],
      },

      // STATISTICS
      // ----------------------------------------------------------------------
      {
        // subheader: t('menu_statistics'),
        items: [],
      },
    ],
    [t]
  );
}
