import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { io } from 'socket.io-client';
import { HOST_API } from 'src/config-global';

const socket = io(HOST_API, {
  path: '/ws',
  autoConnect: false,
  reconnection: true,
  reconnectOnConnectionFailure: true,
  reconnectionDelay: 2000,
  reconnectionAttempts: 5,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
  transports: ['websocket'],
});

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const connectWithToken = useCallback((token) => {
    if (token) {
      socket.auth = { token };
      socket.connect();
    }
  }, []);

  const [socketStatus, setSocketStatus] = useState('disconnected');

  useEffect(() => {
    socket.on('connect', () => {
      setSocketStatus('connected');
    });

    socket.on('disconnect', () => {
      setSocketStatus('disconnected');
    });

    socket.on('connect_error', () => {
      setSocketStatus('error');
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
    };
  }, []);

  const enterRoom = useCallback((room) => {
    socket.emit('enter_room', room);
  }, []);

  const leaveRoom = useCallback((room) => {
    socket.emit('leave_room', room);
  }, []);

  const value = useMemo(
    () => ({
      connectWithToken,
      socketStatus,
      enterRoom,
      leaveRoom,
    }),
    [connectWithToken, socketStatus, enterRoom, leaveRoom]
  );

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// export const useSocket = () => useContext(SocketContext);
export const useSocketContext = () => {
  const context = useContext(SocketContext);

  if (!context) throw new Error('useSocketContext context must be use inside SocketProvider');

  return context;
};

export default socket;
