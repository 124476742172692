import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useAuthContext } from 'src/auth/hooks';
// api
import { endpoints } from 'src/utils/axios';
import { getUserSettings, updateUserSettings } from 'src/api/account';
// components
import { UserSettingsContext } from './user-settings-context';

// ----------------------------------------------------------------------

export function UserSettingsProvider({ children }) {
  const [userSettings, setUserSettings] = useLocalStorage('user_settings', {});
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const settings = await getUserSettings({
          url: endpoints.user.settings.get,
          userId: user.id,
        });

        setUserSettings(settings);
      } catch (error) {
        console.log(error);
      }
    };

    if (user) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onUpdate = useCallback(
    async (name, value) => {
      setUserSettings((prevState) => ({
        ...prevState,
        [name]: value,
      }));

      if (user) {
        try {
          await updateUserSettings({
            url: endpoints.user.settings.update,
            userId: user.id,
            data: { key: name, value },
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    [setUserSettings, user]
  );

  // ----------------------------------------------------------------------

  const memoizedValue = useMemo(
    () => ({
      ...userSettings,
      onUpdate,
    }),
    [onUpdate, userSettings]
  );

  return (
    <UserSettingsContext.Provider value={memoizedValue}>{children}</UserSettingsContext.Provider>
  );
}

UserSettingsProvider.propTypes = {
  children: PropTypes.node,
};
