import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const LexisListPage = lazy(() => import('src/pages/lexi/list'));
const LexiProfilePage = lazy(() => import('src/pages/lexi/profile'));

// ----------------------------------------------------------------------

export const lexisRoutes = [
  {
    path: 'lexis',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <LexisListPage />, index: true },
      { path: 'list', element: <LexisListPage /> },
      { path: ':id', element: <LexiProfilePage /> },
    ],
  },
];
