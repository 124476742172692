import PropTypes from 'prop-types';
import { AxiosError } from 'axios';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// hooks
import axiosInstance, { endpoints } from 'src/utils/axios';
import socket, { useSocketContext } from 'src/utils/socket';
// router
import { useRouter } from 'src/routes/hooks';
// components
import { AuthContext } from './auth-context';
import { isValidToken, setSession, jwtDecode } from './utils';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
  access_token: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
      access_token: action.payload.access_token,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
      access_token: action.payload.access_token,
    };
  }
  if (action.type === 'UPDATE') {
    return {
      ...state,
      user: action.payload.user,
      access_token: action.payload.access_token,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
      access_token: action.payload.access_token,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      access_token: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const ACCESS_TOKEN = 'accessToken';
const USER = 'user';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { connectWithToken } = useSocketContext();
  const router = useRouter();

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(ACCESS_TOKEN);
      const user = sessionStorage.getItem(USER);

      if (accessToken && isValidToken(accessToken)) {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: JSON.parse(user),
            access_token: accessToken,
          },
        });

        connectWithToken(accessToken);
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
            access_token: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
          access_token: null,
        },
      });
    }
  }, [connectWithToken]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      let status;
      let errorData;

      if (error instanceof AxiosError) {
        status = error.response ? error.response.status : null;
        errorData = error.response ? error.response.data : null;
      } else {
        status = error?.status_code || null;
        errorData = error || 'Something went wrong';
      }

      if (status === 403) {
        logout();
        router.replace('/');
      }

      return Promise.reject(errorData);
    }
  );

  axiosInstance.interceptors.request.use((config) => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  });

  // LOGIN
  const login = useCallback(
    async (email, password) => {
      const data = {
        email,
        password,
      };

      const response = await axiosInstance.post(endpoints.auth.login, data);
      const { access_token, user } = response.data;

      setSession(access_token, user);

      connectWithToken(access_token);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          access_token,
        },
      });
    },
    [connectWithToken]
  );

  const update = useCallback(
    async (userData) => {
      const user = { ...state.user, ...userData };
      setSession(state.access_token, user);

      dispatch({
        type: 'UPDATE',
        payload: {
          user,
          access_token: state.access_token,
        },
      });
    },
    [state.access_token, state.user]
  );

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
    };

    const response = await axiosInstance.post(endpoints.auth.register, data);
    const { access_token, user } = response.data;

    setSession(access_token, user);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        access_token,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null, null);

    if (socket.connected) {
      socket.disconnect();
    }

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const decodedToken = jwtDecode(state.access_token);
  const role = decodedToken?.role || 'member';

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      role,
      //
      login,
      register,
      logout,
      update,
    }),
    [login, logout, register, update, state.user, status, role]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
