import axiosInstance, { buildPath } from 'src/utils/axios';

export const getNotifications = async ({ url }) => {
  const URL = buildPath(url, {});
  const response = await axiosInstance.get(URL);
  return response.data;
};

export const getNotificationSettings = async ({ url }) => {
  const URL = buildPath(url, {});
  const response = await axiosInstance.get(URL);
  return response.data;
};

export const updateNotificationSettings = async ({ url, notificationSetting }) => {
  const URL = buildPath(url, { type_id: notificationSetting.id });
  const response = await axiosInstance.put(URL, notificationSetting);
  return response.data;
};

export const updateNotificationSettionOption = (notificationSetting) => ({
  optimisticData: (notificationsSettings) =>
    notificationsSettings.map((item) => {
      if (item.id === notificationSetting.id) {
        return {
          ...item,
          ...notificationSetting,
        };
      }

      return item;
    }),

  rollbackOnError: true,

  populateCache: (newNotificationSetting, notificationsSettings) =>
    notificationsSettings.map((item) => {
      if (item.id === notificationSetting.id) {
        return {
          ...item,
          ...newNotificationSetting,
        };
      }

      return item;
    }),

  revalidate: false,
});

export const markNotificationAsRead = async ({ url, notificationId, status }) => {
  const URL = buildPath(url, { notification_id: notificationId });
  const response = await axiosInstance.put(URL, { status });
  return response.data;
};

export const markNotificationsAsReadOptions = (notification) => ({
  optimisticData: (notifications) =>
    notifications.map((item) => {
      if (item.id === notification.id) {
        return {
          ...item,
          ...notification,
        };
      }

      return item;
    }),

  rollabckOnError: true,

  populateCache: (newNotification, notifications) =>
    notifications.map((item) => {
      if (item.id === notification.id) {
        return {
          ...item,
          ...newNotification,
        };
      }

      return item;
    }),

  revalidate: false,
});

export const markAllAsRead = async ({ url }) => {
  const URL = buildPath(url, {});
  const response = await axiosInstance.put(URL);
  return response.data;
};

export const addNewNotificationOptions = (data) => ({
  optimisticData: (notifications) => [data, ...notifications],
  rollbackOnError: true,
  populateCache: (added, notifications) => [added, ...notifications],
  revalidate: false,
});
