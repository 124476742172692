import axiosInstance, { buildPath } from 'src/utils/axios';

export const postPasswordReset = async ({ url, email }) => {
  const URL = buildPath(url);
  const response = await axiosInstance.post(URL, { email });

  return response.data;
};

export const postNewPassword = async ({ url, uuid, password }) => {
  const URL = buildPath(url);
  const response = await axiosInstance.post(URL, { uuid, password });

  return response.data;
};

export const getUserData = async ({ url, userId }) => {
  const URL = buildPath(url, { user_id: userId });
  const response = await axiosInstance.get(URL);

  return response.data;
};

export const deleteAccount = async ({ url }) => {
  const URL = buildPath(url, {});
  const response = await axiosInstance.delete(URL);
  return response.data;
};

export const updateUserData = async ({ url, userId, data }) => {
  const URL = buildPath(url, { user_id: userId });
  const response = await axiosInstance.put(URL, data);

  return response.data;
};

export const uploadUserPhoto = async ({ url, userId, file }) => {
  const URL = buildPath(url, { user_id: userId });
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  formData.append('image_file', file);

  const response = await axiosInstance.put(URL, formData, config);

  return response.data;
};

export const getUserSettings = async ({ url, userId }) => {
  const URL = buildPath(url, { user_id: userId });
  const response = await axiosInstance.get(URL);

  return response.data;
};

export const updateUserSettings = async ({ url, userId, data }) => {
  const URL = buildPath(url, { user_id: userId });
  const response = await axiosInstance.put(URL, data);

  return response.data;
};
