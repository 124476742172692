import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// LESSON
const LessonListPage = lazy(() => import('src/pages/lesson/list'));
const LessonCreatePage = lazy(() => import('src/pages/lesson/new'));
const LessonDetailsPage = lazy(() => import('src/pages/lesson/details'));
const LessonEditPage = lazy(() => import('src/pages/lesson/edit'));
const LessonTextPage = lazy(() => import('src/pages/lesson/text'));

// ----------------------------------------------------------------------

export const lessonsRoutes = [
  {
    path: 'lessons',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <LessonListPage />, index: true },
      { path: 'list', element: <LessonListPage /> },
      { path: ':id', element: <LessonDetailsPage /> },
      { path: 'new', element: <LessonCreatePage /> },
      { path: ':id/edit', element: <LessonEditPage /> },
      { path: ':id/texts/:target_text_id/:native_text_id', element: <LessonTextPage /> },
    ],
  },
];
