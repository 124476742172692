import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import CompactLayout from 'src/layouts/compact';

// ----------------------------------------------------------------------

const Page404 = lazy(() => import('src/pages/404'));
const EmailConfirmationPage = lazy(() => import('src/pages/account/email-confirmation'));
const NewPasswordPage = lazy(() => import('src/pages/account/new-password'));
const DeleteAccountPage = lazy(() => import('src/pages/account/delete-account'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <CompactLayout>
        <Outlet />
      </CompactLayout>
    ),
    children: [
      { path: '404', element: <Page404 /> },
      {
        path: 'confirm',
        element: <EmailConfirmationPage />,
      },
      {
        path: 'new-password',
        element: <NewPasswordPage />,
      },
      {
        path: 'delete-account',
        element: <DeleteAccountPage />,
      },
    ],
  },
];
