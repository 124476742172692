import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const StudentListPage = lazy(() => import('src/pages/student/list'));
const StudentProfilePage = lazy(() => import('src/pages/student/profile'));

// ----------------------------------------------------------------------

export const studentsRoutes = [
  {
    path: 'students',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <StudentListPage />, index: true },
      { path: 'list', element: <StudentListPage /> },
      { path: ':id', element: <StudentProfilePage /> },
    ],
  },
];
