import { useContext } from 'react';
//
import { UserSettingsContext } from './user-settings-context';

// ----------------------------------------------------------------------

export const useUserSettingsContext = () => {
  const context = useContext(UserSettingsContext);

  if (!context)
    throw new Error('useUserSettingsContext context must be use inside UserSettingsProvider');

  return context;
};
