import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

/**
 * Build path with params.
 *
 * ### Details
 *
 * This function replaces the parts in [xxx] with the values from params. Any additional params
 * are added as query string.
 *
 * ### Example
 *
 * ```js
 * buildPath(
 *    '/lessons/[lesson_id]/texts/[text_id]',
 *    { lesson_id: 1, text_id: 2, extra: 'value' }
 * )
 * // => '/lessons/1/texts/2?extra=value'
 * ```
 *
 *
 * @param path string with [xxx] parts to replace with params values
 *             (e.g. /lessons/[lesson_id]/texts/[text_id])
 * @param params object with params values (e.g. { lesson_id: 1, text_id: 2 })
 * @returns {*} string with replaced parts (e.g. /lessons/1/texts/2)
 */
export const buildPath = (path, params = {}) => {
  const used = new Set();

  // Replace the parts in [xxx]
  path = path.replace(/\[([^\]]+)]/g, (m, c0) => {
    used.add(c0);
    return c0 in params ? params[c0] : '';
  });

  // Add query string if there are any left over
  const qstr = Object.entries(params)
    .filter(([key]) => !used.has(key))
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  return path + (qstr && `?${qstr}`);
};

export const no_reload_config = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  revalidateOnMount: true,
  refreshWhenHidden: false,
  refreshWhenOffline: false,
  refreshInterval: 0,
  shouldRetryOnError: false,
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    me: '/api/auth/me',
    login: '/auth/sign-in',
    register: '/auth/sign-up',
  },
  account: {
    passwordReset: '/account/password-reset',
    newPassword: '/account/new-password',
    deleteAccount: '/account',
  },
  confirm: {
    email: '/confirm/email',
    check: '/confirm/check',
    deleteAccount: '/confirm/delete-account',
  },
  notifications: {
    get: '/notifications',
    markAsRead: '/notifications/[notification_id]',
    markAllAsRead: '/notifications/read-all',

    settings: {
      get: '/notifications/settings',
      update: '/notifications/settings/[type_id]',
    },
  },

  students: '/students',

  users: '/users',
  user: {
    get: '/users/[user_id]',
    upload: '/users/[user_id]/avatar',

    settings: {
      get: '/users/[user_id]/settings',
      update: '/users/[user_id]/settings',
    },

    lesson: {
      get: '/users/[user_id]/lessons/[lesson_id]',
      toggleBookmark: '/users/[user_id]/lessons/[lesson_id]/toggle-bookmark',
      unlock: '/users/[user_id]/lessons/[lesson_id]/unlock',
      recent: '/users/[user_id]/lessons/recent',
    },

    friends: {
      get: '/users/[user_id]/friends',
      create: '/users/[user_id]/friends',
      block: '/users/[user_id]/block',
      unblock: '/users/[user_id]/unblock',
    },
  },

  friendships: {
    accept: '/friendships/[friendship_id]/accept',
    reject: '/friendships/[friendship_id]/reject',
    cancel: '/friendships/[friendship_id]/cancel',
    delete: '/friendships/[friendship_id]',
  },

  lessons: '/lessons',
  lesson: {
    get: '/lessons/[lesson_id]',
    texts: '/lessons/[lesson_id]/texts',
    text: '/lessons/[lesson_id]/texts/[text_id]',
    publishText: '/lessons/[lesson_id]/texts/[text_id]/publish',
    generateText: '/lessons/[lesson_id]/texts/generate',
  },

  lexies: {
    get: '/lexies',
  },

  text: {
    pronunciations: '/texts/[text_id]/pronunciations',
    statuses: '/texts/[text_id]/statuses',
    parts: '/texts/[text_id]/parts',
  },
  audio: {
    get: '/media/[uuid]/audio',
  },
};
