import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { studentsRoutes } from './students';
import { lessonsRoutes } from './lessons';
import { accountRoutes } from './account';
import { lexisRoutes } from './lexis';
import { friendsRoutes } from './friends';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Account routes
    ...accountRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Friends routes
    ...friendsRoutes,

    // Lessons routes
    ...lessonsRoutes,

    // Students routes
    ...studentsRoutes,

    // Lexis routes
    ...lexisRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
