import { useContext } from 'react';
import { useLocales } from 'src/locales';
import { SocketContext } from './socket';
import Label from '../components/label';

function ConnectionIndicator() {
  const { socketStatus } = useContext(SocketContext);
  const { t } = useLocales();

  return (
    <Label
      variant="soft"
      color={
        (socketStatus === 'connected' && 'success') ||
        (socketStatus === 'disconnected' && 'warning') ||
        (socketStatus === 'error' && 'error') ||
        'default'
      }
    >
      {t(`socket.${socketStatus}`)}
    </Label>
  );
}

export default ConnectionIndicator;
